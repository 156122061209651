<template lang="html">
	<div class="table-banco">
		<vx-card class="formulario" v-if="mostrarTabla">
			<span class="card__header"><h4 class="titulo">Contraseña por banco</h4></span>
			<vs-row class="row w-full">
				<div class="col-md-9"></div>
				<div class="col-md-3">
					<button class="btn btn-primary" type="border" @click="verFormularioNuevo()"><span style="font-size: 18px; font-weight: bold">+ </span>Nuevo registro</button>
				</div>
			</vs-row>
			<br />
			<VueGoodTable
				styleClass="vgt-table condensed"
				:columns="columns"
				:rows="rows"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPageDropdown: [5, 25],
					dropdownAllowAll: false,
					rowsPerPageLabel: 'Mostrar',
					ofLabel: 'registros de un total de',
					pageLabel: false, // for 'pages' mode
					nextLabel: 'siguiente',
					prevLabel: 'anterior',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field == 'Acciones'">
						<a class="btn btn-sm primary" @click="EditaRegistro(props.row.Id)" style="color: blue">Editar</a>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</VueGoodTable>
		</vx-card>
		<vx-card class="formulario" v-if="mostrarFormulario">
			<div class="row w-full" vs-w="12" vs-justify="space-between">
				<span class="card__header"
					><h4 class="titulo">{{ textoTiluto }}</h4></span
				>
			</div>
			<vs-row class="row w-full">
				<div class="col-md-7"></div>
				<div class="col-md-3" vs-justify="space-end">
					<button class="btn btn-primary" type="border" @click="regresar()">Regresar</button>
				</div>
			</vs-row>

			<div class="form-group row w-full">
				<label for="financiera" class="col-sm-2 col-form-label">Financiera</label>
				<div class="col-sm-8">
					<select class="form-control financiera" name="financiera" id="financiera" v-model="financiera" @change="updateTipoCuenta()">
						<option :value="tipo.Id" v-for="tipo in bancosEjecutivo">{{ tipo.Nombre }}</option>
					</select>
				</div>
			</div>
			<div class="form-group row w-full">
				<label for="usuario" class="col-sm-2 col-form-label">Usuario</label>
				<div class="col-sm-8">
					<input type="text" minlength="6" maxlength="35" v-model="usuario" class="form-control" id="usuario" @change="ValidaUsuario()" />
					<label style="color: red; display: none" class="mensaje">*La longitud del usuario no puede ser menor a 6 ni mayor a 35</label>
				</div>
			</div>
			<div class="form-group row w-full">
				<label for="contrasenia" class="col-sm-2 col-form-label">Contraseña</label>
				<div class="col-sm-8">
					<!--<input type="password" v-model="password" class="form-control" id="contrasenia" >-->
					<VuePassword v-model="password" id="password" :disableStrength="true" type="password" minlength="6" maxlength="15" @change="ValidaContrasena()" />
					<label style="color: red; display: none" class="mensajeContra">*La longitud de la contraseña debe estar entre 6 y 15 caracteres</label>
				</div>
			</div>
			<div class="form-group row w-full">
				<label for="contrasenia" class="col-sm-2 col-form-label">Estatus</label>
				<div class="col-sm-8">
					<vs-switch v-model="estatus" />
				</div>
			</div>
      <div class="form-group row w-full"  v-if="mostrarTipoCuenta">
				<label for="tipoCuenta" class="col-sm-2 col-form-label">Tipo de Cuenta</label>
				<div class="col-sm-8">
					<div class="row">
            <div class="col-md-6">
              <vs-radio v-model="tipoCuenta" vs-name="tipoCuenta" vs-value="Captura" id="Captura"></vs-radio>
              <label class="span-placeholder" for="Captura">Captura</label>
            </div>
            <div class="col-md-6">
              <vs-radio v-model="tipoCuenta" vs-name="tipoCuenta" vs-value="Supervisor" id="Supervisor"></vs-radio>
              <label class="span-placeholder" for="Supervisor">Supervisor</label>
            </div>
          </div>
				</div>
			</div>
			<div class="form-group row w-full" v-if="editar">
				<label for="fechaAlta" class="col-sm-2 col-form-label">Fecha alta</label>
				<div class="col-sm-8">
					<input type="text" v-model="fechaAlta" class="form-control" id="fechaAlta" readonly style="border: 0px; background-color: white" />
				</div>
			</div>
			<div class="form-group row w-full" v-if="editar">
				<label for="ultimaActualizacion" class="col-sm-2 col-form-label">Ultima actualización</label>
				<div class="col-sm-8">
					<input type="text" v-model="ultimaActualizacion" class="form-control" id="ultimaActualizacion" readonly style="border: 0px; background-color: white" />
				</div>
			</div>
			<div class="row w-full" vs-w="12" vs-justify="">
				<div class="col-md-2"></div>
				<div class="col-md-3">
					<button class="btn btn-primary mt-2" type="border" @click="GuardaBanco()">Guardar</button>
				</div>
				<div class="col-md-3">
					<button class="btn btn-danger mt-2" type="border" @click="cancelar()">Cancelar</button>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
import VuePassword from 'vue-password'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { hidden } from 'caniuse-lite/data/features'
import { blue, red } from 'color-name'
export default {
	components: {
		VuePassword,
		VueGoodTable,
	},
	data() {
		return {
			cantidades: [
				{ Nombre: '15', id: 15 },
				{ Nombre: '25', id: 25 },
			],
			contrasenias: [],
			cantidad: 15,
			mostrarTabla: true,
			mostrarFormulario: false,
			financiera: '',
			usuario: '',
			password: '',
			estatus: false,
			mostrarTipoCuenta:'',
      tipoCuenta: '',
			fechaAlta: '',
			ultimaActualizacion: '',
			editar: true,
			textoTiluto: 'Nueva contraseña de banco',
			columns: [
				{
					label: 'Fecha de Alta',
					field: 'FechaRegistro',
					sortable: true,
				},
				{
					label: 'Banco',
					field: 'Banco',
					sortable: true,
				},
				{
					label: 'Última modificación',
					field: 'FechaModificacion',
					sortable: true,
				},
				{
					label: 'Estatus',
					field: 'Activo',
					sortable: true,
				},
				{
					label: 'Acciones',
					field: 'Acciones',
					sortable: false,
				},
				{
					field: 'Id',
					sortable: false,
					hidden: true,
				},
			],
			rows: [],
			bancosEjecutivo: [],
			bancos: [],
			bancos2: [],
		}
	},
	mounted() {
		this.id = this.$route.params.id
		this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
		this.gettabla()
		this.getBancosEjecutivo()
	},
	methods: {
		verFormularioNuevo() {
			this.mostrarTabla = false
			this.mostrarFormulario = true
			this.mostrarTipoCuenta= false
			this.textoTiluto = 'Nueva contraseña de banco'
			this.bancosEjecutivo = []
			this.getBancosEjecutivo()
		},
		regresar() {
			this.mostrarTabla = true
			this.mostrarFormulario = false
			this.limpiarFormulario()
		},
		cancelar() {
			this.limpiarFormulario()
			this.mostrarTabla = true
			this.mostrarFormulario = false
		},
		limpiarFormulario() {
			this.financiera = ''
			this.usuario = ''
			this.password = ''
			this.estatus = false
			this.accion = ''
			this.fechaAlta = ''
			this.ultimaActualizacion = ''
		},
		gettabla() {
			let self = this
			var objRequestListaTabla = {
				strApiKey: this.key,
				strMetodo: 'ListaBrokerBanco',
				objEjecutivo: {
					EMail: JSON.parse(localStorage.getItem('userInfo')).EMail,
				},
			}
			this.$axios
				.post('/', objRequestListaTabla, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					response.data.objContenido
					//this.rows = response.data.objContenido
					let registro = response.data.objContenido
					for (var i = 0; i < registro.length; i++) {
						let fecha = registro[i].FechaRegistro.date.substring(0, 10)
						let modificacion = registro[i].FechaModificacion.date.substring(0, 10)

						fecha = fecha.split('-', 3)
						fecha = fecha[2] + '/' + fecha[1] + '/' + fecha[0]

						modificacion = modificacion.split('-', 3)
						modificacion = modificacion[2] + '/' + modificacion[1] + '/' + modificacion[0]

						let acciones = 'Edita'

						this.rows.push({ FechaRegistro: fecha, Banco: registro[i].Banco, FechaModificacion: modificacion, Activo: registro[i].Activo, Id: registro[i].Id, Acciones: acciones })
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema4',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		updateTipoCuenta(){
			
			if(this.financiera==11){
				this.mostrarTipoCuenta= true
			}else{
				this.mostrarTipoCuenta= false
			}

		},
		getBancosEjecutivo() {
			let self = this
			var objRequestListaFinancieras = {
				strApiKey: this.key,
				strMetodo: 'ListaFinancieras',
				objEjecutivo: {
					EMail: JSON.parse(localStorage.getItem('userInfo')).EMail,
				},
			}
			this.$axios
				.post('/', objRequestListaFinancieras, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.bancos = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

			var objRequestListaTabla = {
				strApiKey: this.key,
				strMetodo: 'ListaBrokerBanco',
				objEjecutivo: {
					EMail: JSON.parse(localStorage.getItem('userInfo')).EMail,
				},
			}
			this.$axios
				.post('/', objRequestListaTabla, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					response.data.objContenido
					//this.rows = response.data.objContenido
					let registro = response.data.objContenido
					for (var i = 0; i < registro.length; i++) {
						this.bancos2.push({ Id: registro[i].BancoId, Nombre: registro[i].Banco })
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema4',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

			var array = []
			for (var i = 0; i < this.bancos.length; i++) {
				var igual = false
				for (var j = 0; (j < this.bancos2.length) & !igual; j++) {
					if (this.bancos[i]['Id'] == this.bancos2[j]['Id'] && this.bancos[i]['Nombre'] == this.bancos2[j]['Nombre']) igual = true
				}
				if (!igual) array.push(this.bancos[i])
			}
			this.bancosEjecutivo = array
		},
		GuardaBanco() {
			let pasa = true
			if (this.fechaAlta == '') {
				if (this.ValidaContrasena()) {
					pasa = false
				}
			}

			if (!this.ValidaUsuario() && pasa) {
				let Activo = 0
				if (this.estatus == true) {
					Activo = 1
				}

				const objRequestBancos = {
					strApiKey: '64bdbfe6ad69ab8a74d7606f08d6e8f1',
					strMetodo: 'RegistraBancosPerfil',
					objEjecutivo: {
						EMail: JSON.parse(localStorage.getItem('userInfo')).EMail,
						Banco: this.financiera,
						Contrasenia: this.password,
						Usuario: this.usuario,
						Activo: Activo,
            TipoCuenta: this.tipoCuenta
					},
				}

        // return console.log( objRequestBancos )

				this.$axios
					.post('/', objRequestBancos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {

            // return console.log(response)

						if (response.data.intEstatus == 0) {
							this.rows = []
							this.getBancosEjecutivo()
							this.gettabla()
							this.regresar()
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en RegistraBancosPerfil',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
			}
		},
		EditaRegistro(params) {

      // console.log(params)

			const objRequestBancos = {
				strApiKey: this.key,
				strMetodo: 'DameBrokerBancoId',
				objCuenta: {
					Id: params,
				},
			}

			this.$axios
				.post('/', objRequestBancos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {

            // console.log(response.data.objContenido)

						this.mostrarTabla = false
						this.mostrarFormulario = true
						
						let registro = response.data.objContenido
						this.usuario = registro.Usuario

						this.bancosEjecutivo = []
						this.bancosEjecutivo.push({ Id: registro.BancoId, Nombre: registro.Banco, selected: true })

						this.financiera = registro.BancoId

						this.textoTiluto = 'Editar contraseña'

						let HoraFecha = registro.FechaRegistro.date.substring(10)
						HoraFecha = HoraFecha.substring(0, 6)
						let fecha = registro.FechaRegistro.date.substring(0, 10)
						let tiempo = HoraFecha.substring(0, 3)
						let pre = ''
						if (tiempo < 12) {
							pre = 'a.m.'
						} else {
							pre = 'p.m.'
						}

						let modificacion = registro.FechaModificacion.date.substring(0, 10)
						let HoraModi = registro.FechaModificacion.date.substring(10)
						let tiempo2 = HoraModi.substring(0, 3)
						HoraModi = HoraModi.substring(0, 6)
						let pre2 = ''
						if (tiempo2 < 12) {
							pre2 = 'a.m.'
						} else {
							pre2 = 'p.m.'
						}

						fecha = fecha.split('-', 3)
						fecha = fecha[2] + '/' + fecha[1] + '/' + fecha[0] + '  - ' + HoraFecha + '  ' + pre

						modificacion = modificacion.split('-', 3)
						modificacion = modificacion[2] + '/' + modificacion[1] + '/' + modificacion[0] + '  - ' + HoraModi + '  ' + pre2

						this.fechaAlta = fecha
						this.ultimaActualizacion = modificacion

						if (registro.Activo == 'Inactivo') {
							this.estatus = false
						} else {
							this.estatus = true
						}

						if(this.financiera==11){
							this.mostrarTipoCuenta= true
						}else{
							this.mostrarTipoCuenta= false
						}



            this.tipoCuenta = registro.TipoCuenta
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraBancosPerfil',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		ValidaUsuario() {
			if (this.usuario.length < 6 || this.usuario.length > 35) {
				$('.mensaje').show()
				return true
			} else {
				$('.mensaje').hide()
				return false
			}
		},
		ValidaContrasena() {
			if (this.password.length < 6 || this.password.length > 15) {
				$('.mensajeContra').show()
				return true
			} else {
				$('.mensajeContra').hide()
				return false
			}
		},
	},
}
</script>

<style scoped>
.vx-card{
padding: 0; 
}
.btn-primary{
  background: #006D4E;
  background-color: #006D4E;
}
.btn-danger,
.btn-primary{
  width: 100%;
  border-radius: 20px;
  border: #626262 1px solid;
  padding: 0.55rem;
}
</style>