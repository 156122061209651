<template>

  <div class="layout--main" >
    <template >
      <the-navbar-vertical-perfil />
    </template>
    <!-- /Navbar -->

      <div class="">

        <div class="router-view">
          <div class="router-content">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                <!-- DROPDOWN -->
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>

            <div class="vistaInt">
                <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="" id="profile-tabs" :key="isSmallerScreen">
    <vs-tab   icon-pack="feather" icon="icon-settings" :label="!isSmallerScreen ? 'General' : ''">
      <Generales/>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-file-text" :label="!isSmallerScreen ? 'Reportes de crédito' : ''">
      <Reportes/>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-pie-chart" :label="!isSmallerScreen ? 'Reportes' : ''">
      <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
        <GraficasSoc/>
      </div>
    </vs-tab>

    <!-- <vs-tab icon-pack="feather" icon="icon-bookmark" :label="!isSmallerScreen ? 'Widget' : ''">
      <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card  class="">
          <Widget/>
        </vx-card>
      </div>
    </vs-tab> -->

  <!---  <vs-tab icon-pack="feather" icon="icon-bookmark" :label="!isSmallerScreen ? 'Acceso de precalificador' : ''">
      <div>
        <Precalificador />
      </div>
    </vs-tab>-->

    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Contraseñas Banco' : ''">
      <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card  class="">
          <ContraseniaBancos/>
        </vx-card>
      </div>
    </vs-tab>
  </vs-tabs>


             
            </div>
          </div>
        </div>
      </div>
<div class="footer">COPYRIGHT 2021 Cotizador SOC, todos los derechos reservados.</div>    </div>
  
</template>

<script>
import TheNavbarVerticalPerfil   from '@/layouts/components/navbar/TheNavbarVerticalPerfil.vue'

import Generales from '@/views/Perfil/Generales.vue'
import Reportes from '@/views/Perfil/Reportes.vue'
import GraficasSoc from '@/views/Perfil/GraficasSoc.vue'
// import Widget from '@/views/Perfil/Widget.vue'
import ContraseniaBancos from '@/views/Perfil/ContraseniasBancos.vue'
import Prueba from '@/views/Perfil/pruebaTabla.vue'
//import Precalificador from '@/views/Caso/Precalificador/Precalificador.vue'

export default {
  components: {
    TheNavbarVerticalPerfil,
    Generales,
    Reportes,
    GraficasSoc,
    // Widget,
    ContraseniaBancos,
    Prueba
    //Precalificador
  },
  data(){
    return{
    }
  },
  mounted(){
    if (this.$store.state.AppActiveUser.WidgetColorPrimario != '') {
      this.$vs.theme({
        primary:this.$store.state.AppActiveUser.WidgetColorPrimario
      })
    }
  },
  computed: {
    isSmallerScreen () {
      return window.screen.width < 768
    }
  }
}
</script>

<style lang="css" scoped>


.button.vs-tabs--btn
{background: transparent;}

.vista{padding-top: 50px !important;}
.table-custom{
 
}
iframe{
  border: none;
}
.selectExample {
  width: 30%;
}
@media only screen and (max-width: 600px) {
  .selectExample{
   
  }
}
</style>
